const parseErrorMessage = error => {
  if (error.response?.data?.message) {
    return error.response.data.message;
  }
  if (error.message) {
    return error.message;
  }
  if (typeof error === 'string') {
    return error;
  }

  return null;
};

const clearUserData = async () => {
  await localStorage.clear();
};

const isEmpty = obj =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

const callAll = (...fns) => (...args) => fns.map(fn => fn && fn(...args));

const callAllEach = (...fns) => (...args) => {
  fns.forEach(fn => fn && fn(...args));
};

const pickBy = (object, includeFalse = false) => {
  const obj = {};

  Object.keys(object).forEach(key => {
    if (object[key] || (includeFalse ? object[key] === false : false)) {
      obj[key] = object[key];
    }
  });

  return obj;
};

const pick = (object, keys) =>
  keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {});

export {
  parseErrorMessage,
  clearUserData,
  isEmpty,
  callAll,
  callAllEach,
  pick,
  pickBy,
};
