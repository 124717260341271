import axios from 'axios';

import { clearUserData, pickBy } from 'utils/common';

/**
 * Handle Network Requests.
 * @param {string} endpoint - Api path.
 * @param {object} [config={}] - Config object.
 * @param {string} config.method - Method.
 * @param {object} config.data - Body for POST calls.
 * @param {string} config.token - Token for authenticated calls.
 * @param {object} config.headers - Additional headers
 */

const client = async (
  endpoint,
  {
    data,
    token,
    headers,
    method,
    transform = true,
    absoluteUrl = false,
    ...rest
  } = {},
) => {
  const config = {
    url: absoluteUrl
      ? endpoint
      : `${process.env.REACT_APP_API_URL}/${endpoint}`,
    method: method || (data ? 'POST' : 'GET'),
    data: data ? JSON.stringify(data) : undefined,
    headers: pickBy({
      Authorization: token || undefined,
      'Content-Type': data ? 'application/json' : undefined,
      ...headers,
    }),
    transformResponse: [].concat(axios.defaults.transformResponse, resp => {
      if (transform && resp.items) {
        return resp.items;
      }
      return resp;
    }),
    ...rest,
  };

  try {
    const response = await axios(config);
    const { data: resData } = response;

    return resData;
  } catch (err) {
    if (token && err.response?.status === 401) {
      window.location.assign(window.location.href);
      err.response.data.message = 'Please re-authenticate.';
      await clearUserData();
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
};

// eslint-disable-next-line import/prefer-default-export
export { client };
