import { client } from 'services/api-client';
import { clearUserData } from 'utils/common';
import { authTokenKey } from 'utils/constants';

async function getToken() {
  return window.localStorage.getItem(authTokenKey);
}

function handleUserResponse(user) {
  window.localStorage.setItem(authTokenKey, user.sessionToken);
  return user;
}

function login(data) {
  return client('users/login', { data }).then(handleUserResponse);
}

function register(data) {
  return client('users', { data }).then(handleUserResponse);
}

async function logout() {
  await client(`users/logout`, {
    method: 'DELETE',
    token: await getToken(),
  });

  return await clearUserData();
}

const forgotPassword = async email => {
  await client(`users/forgot-password?email=${encodeURIComponent(email)}`);
};

const resetPassword = payload =>
  client('users/reset-password', {
    data: { ...payload },
    method: 'POST',
  });

export { getToken, login, register, logout, forgotPassword, resetPassword };
